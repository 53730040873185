import React from "react";
import { StaticQuery, graphql } from "gatsby";

import Layout from "../containers/layout";
import SEO from "../components/seo";
import Container from "../components/container";
import PortableText from "../components/portableText";
import stylesMain from "../components/main.module.css";

const query = graphql`
  query Default404Query {
    site: sanitySiteSettings(_id: { eq: "siteSettings" }) {
      errorTitle
      _rawErrorBody(resolveReferences: { maxDepth: 5 })
      errorMetaTitle
      errorMetaDescription
    }
  }
`;

const NotFoundPage = () => {
  return (
    <StaticQuery
      query={query}
      render={(data) => {
        const site = (data || {}).site;
        if (!site) return;

        const title = site.errorTitle || "";
        const metaTitle = site.errorMetaTitle || "";
        const metaDescription = site.errorMetaDescription || "";

        return (
          <Layout>
            <SEO lang={"nl"} path="/404" metaTitle={metaTitle} metaDescription={metaDescription} />
            <Container>
              <div className={stylesMain.mainContent}>
                <h1 className={stylesMain.title}>{title}</h1>
                {site._rawErrorBody && <PortableText blocks={site._rawErrorBody} />}
              </div>
            </Container>
          </Layout>
        );
      }}
    />
  );
};

export default NotFoundPage;
